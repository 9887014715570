import React, { Suspense, useEffect } from 'react';
import Routes from './config/routes';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '@config/apollo-client';
import SplashScreen from '@layouts/SplashScreen';
import SnackbarProvider from 'react-simple-snackbar';
import { watchCartChanges } from 'modules/cart/services/watch-cart-changes';
import { getCurrentUserProfile } from 'modules/account/services/auth-service';

const App = () => {
  const apolloClient = useApollo();
  const currentUser = getCurrentUserProfile();

  useEffect(() => {
    if (!currentUser) return;
    watchCartChanges(apolloClient, currentUser.accountID);
  }, [currentUser]);

  return (
    <ApolloProvider client={apolloClient}>
      <SnackbarProvider>
        <Suspense fallback={<SplashScreen />}>
          <Routes />
        </Suspense>
      </SnackbarProvider>
    </ApolloProvider>
  );
};

export default App;
