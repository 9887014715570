import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Label from './Label';
import { ReactComponent as ChevronDownIcon } from '@assets/svg/chevron-down.svg';

const CustomSelect = styled.select`
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  border-radius: 4px;
  background: transparent;
  z-index: 50;
`;

function Select(props) {
  return (
    <div className={`${props.className ? props.className : 'mb-4'}`}>
      {!!props.label && <Label htmlFor={props.name}>{props.label}</Label>}
      <div className="relative">
        <CustomSelect
          {...props}
          className={`w-full border border-gray-200 focus:border-gray-700 font-medium appearance-none py-3 px-3 text-gray-700 leading-tight focus:outline-none`}
        >
          {props.children}
        </CustomSelect>
        <div className="absolute top-0 right-4 bottom-0 pl-4 flex flex-col justify-center z-hide">
          <ChevronDownIcon />
        </div>
      </div>
    </div>
  );
}

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  iconLeft: PropTypes.any,
  centered: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
};

export default Select;
