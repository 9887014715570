import React from 'react';
import PropTypes from 'prop-types';

function Card(props) {
  return (
    <div className={`bg-white w-full px-6 py-6 ${props.className}`}>
      {props.children}
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Card;
