import { ModalProvider } from '@components/modals';
import { isAnonymous } from '@modules/account/services/auth-service';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect, Route, Router, useLocation } from 'react-router-dom';
import AccountRoutes, { accountRoutes } from '../modules/account/routes';
import CartRoutes from '../modules/cart/routes';
import CellarRoutes, { cellarRoutes } from '../modules/cellar/routes';
import CheckoutRoutes from '../modules/checkout/routes';

export const initialRoute = cellarRoutes.wines;
export const history = createBrowserHistory();

export function requiresAuth(renderFn) {
  return () => {
    if (isAnonymous()) {
      return <Redirect to={accountRoutes.login} />;
    }
    return renderFn();
  };
}

export function redirectIfLoggedIn(to, renderFn) {
  return () => {
    if (!isAnonymous()) {
      return <Redirect to={to} />;
    }
    return renderFn();
  };
}

function ScrollToTopOnLocationChange() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Routes(props) {
  return (
    <Router history={history}>
      <ScrollToTopOnLocationChange />
      <ModalProvider>
        <AccountRoutes />
        <CartRoutes />
        <CellarRoutes />
        <CheckoutRoutes />
        <Route
          exact
          path={'/'}
          render={requiresAuth(() => (
            <Redirect to={initialRoute} />
          ))}
        />
        {props.children}
      </ModalProvider>
    </Router>
  );
}

Routes.propTypes = { children: PropTypes.any };

export default Routes;
