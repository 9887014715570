import React from 'react';
import PropTypes from 'prop-types';

function PageTitle(props) {
  function getCssClasses() {
    let classes = [];
    props.className
      ? classes.push(props.className)
      : classes.push('text-3xl uppercase');
    return classes.join(' ');
  }

  function getWrapperCssClasses() {
    let classes = [];
    props.wrapperClassName
      ? classes.push(props.wrapperClassName)
      : classes.push('pb-2 mb-3');
    return classes.join(' ');
  }

  return (
    <div>
      <div className={`${getWrapperCssClasses()}`}>
        <h1
          className={`font-barlow-condensed font-semibold text-gray-700 ${getCssClasses()}`}
        >
          {props.title}
        </h1>
      </div>
      {!props.noBorder && (
        <div
          className={props.border ? props.border : 'border-b border-gray-100'}
        ></div>
      )}
    </div>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  border: PropTypes.string,
  noBorder: PropTypes.bool,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default PageTitle;
