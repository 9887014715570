import pusherClient, { CART_UPDATED } from '@config/pusher-client';
import { CURRENT_USER_CART } from 'modules/cart/graphql/queries';

function onCartUpdated(apolloClient) {
  console.log(`Refetching CURRENT_USER_CART`);
  apolloClient.query({
    query: CURRENT_USER_CART,
  });
}

export function watchCartChanges(apolloClient, accountID) {
  console.log('watchCartChanges', accountID);
  const channelName = `account--${accountID}`;
  const channel = pusherClient.subscribe(channelName);
  channel.bind(CART_UPDATED, () => onCartUpdated(apolloClient));
}
