import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ChevronDownIcon } from '@assets/svg/chevron-down.svg';
import Label from './Label';
import { isTouchDevice } from '@helpers/device-capabilities-helper';

function Dropdown(props) {
  const dropdownRef = useRef();
  const [visible, setVisible] = useState(false);
  const [opened, setOpened] = useState(false);

  function getTransitionClasses() {
    return opened
      ? 'ease-in duration-75 opacity-100 scale-100'
      : 'ease-out duration-100 opacity-0 scale-95';
  }

  function getVisibleClass() {
    return !visible ? 'hidden' : 'visible';
  }

  function toggleOpened(isOpen) {
    if (isOpen) {
      setVisible(true);
      setTimeout(() => setOpened(true), 50);
    } else {
      setOpened(false);
      setTimeout(() => setVisible(false), 50);
    }
  }

  function getOptionLabel(value) {
    let options = props.options || [];
    let option = options.find((opt) => opt.value == value) || {};
    return option.label || '';
  }

  useEffect(() => {
    let eventName = isTouchDevice() ? 'touchstart' : 'mousedown';
    document.addEventListener(eventName, (ev) => {
      if (
        dropdownRef &&
        dropdownRef.current &&
        !dropdownRef.current.contains(ev.target)
      ) {
        toggleOpened(false);
      }
    });
  }, []);

  return (
    <div className="relative cursor-pointer">
      <div
        className="flex flex-row items-center"
        onClick={() => toggleOpened(!opened)}
      >
        <Label className="mb-0 pr-2 cursor-pointer select-none group hover:underline">
          {props.label}
          <span
            className={`${
              props.hideLabelOnMobile
                ? 'hidden sm:inline-block group-hover:underline'
                : ''
            }`}
          >
            {props.value && `: ${getOptionLabel(props.value)}`}
          </span>
        </Label>
        <ChevronDownIcon />
      </div>
      <div
        ref={dropdownRef}
        className={`transition ${getVisibleClass()} ${getTransitionClasses()} origin-top-right relative lg:absolute left-0 mt-2 w-56 z-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
      >
        <div className="py-1" role="none">
          {props.options &&
            props.options.map((opt, index) => (
              <span
                key={index}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                role="menuitem"
                onClick={(ev) => {
                  toggleOpened(false);
                  opt.onClick && opt.onClick(ev);
                }}
              >
                {opt.label}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  hideLabelOnMobile: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default Dropdown;
