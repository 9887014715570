import React from 'react';
import PropTypes from 'prop-types';

function SecondaryButton(props) {
  function getCssClasses() {
    let classes = [];

    props.className && classes.push(props.className);

    if (props.disabled) classes.push('opacity-70');
    if (!props.disabled) classes.push('hover:opacity-80');
    classes.push(props.big ? 'py-4.5 text-xl' : 'py-2 h-10');
    return classes.join(' ');
  }

  function getLabelCssClasses() {
    let classes = [];
    if (props.loading) classes.push('invisible');
    return classes.join(' ');
  }

  function getLoaderPosition() {
    return !props.big ? 'top-2.5' : '';
  }

  return (
    <button
      className={`relative bg-transparent truncate text-gray-700 border-2 border-gray-700 px-5 w-full focus:outline-none font-barlow-condensed uppercase leading-none ${getCssClasses()}`}
      type={props.type}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.loading && (
        <svg
          className={`absolute left-1/2 ${getLoaderPosition()} top-2 -ml-2.5 animate-spin h-5 w-5 text-gray-700 mx-auto`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      <span className={`whitespace-nowrap block ${getLabelCssClasses()}`}>
        {props.label}
      </span>
    </button>
  );
}

SecondaryButton.propTypes = {
  label: PropTypes.string,
  big: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default SecondaryButton;
