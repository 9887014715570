import React from 'react';
import PropTypes from 'prop-types';

function SectionTitle(props) {
  function getBorderClass() {
    return props.border ? 'border-b-3 pb-2 border-gray-700' : '';
  }

  function getColorClass() {
    return props.colorClass ? props.colorClass : 'text-gray-700';
  }

  return (
    <div
      className={`${getBorderClass()} ${getColorClass()} ${
        props.className ? props.className : 'mb-5'
      }`}
    >
      <h2 className="font-barlow-condensed font-semibold text-xl">
        {props.title}
      </h2>
    </div>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  colorClass: PropTypes.string,
  border: PropTypes.bool,
};

export default SectionTitle;
