import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { requiresAuth } from '../../config/routes';

const Cart = lazy(() => import('./pages/Cart'));

export const cartRoutes = {
  cart: '/cart',
};

export default function CartRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={requiresAuth(() => (
          <Cart />
        ))}
        path={cartRoutes.cart}
      />
    </Switch>
  );
}
