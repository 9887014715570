import React from 'react';
import logoUrl from '@assets/images/phenol-logo.png';

export default function SplashScreen() {
  return (
    <>
      <div className={'h-screen bg-gray-900 flex flex-col justify-center'}>
        <div className="flex flex-row justify-center">
          <img src={logoUrl} />
        </div>
      </div>
    </>
  );
}
