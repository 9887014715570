import { makeVar } from '@apollo/client';

export const checkoutVar = makeVar({
  checkoutMethod: 'pickup',
  address: {
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  },
  date: null,
  time: '',
});

export default {
  checkout: {
    read() {
      return checkoutVar();
    },
  },
};
