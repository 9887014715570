import { gql } from '@apollo/client';

export default gql`
  query cart {
    account {
      _id
      firstname
      lastname
      billinguser {
        _id
        username
        email
        firstname
        lastname
        phone
        roles
        shipaddress
        shipcity
        shipcountry
        shipfirstname
        shiplastname
        shipphone
        shipstate
        shipzip
        state
        zip
      }
      cart {
        wines {
          _id
          size
          ammount
        }
      }
      savedForLater {
        wines {
          _id
          size
          ammount
        }
      }
    }
  }
`;
