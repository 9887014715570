import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAuthToken } from '@modules/account/services/auth-service';
import { cellarApolloCache } from '@modules/cellar/graphql/cache';
import {
  checkoutApolloCache,
  checkoutTypeDefs,
} from '@modules/checkout/graphql/cache';
import merge from 'lodash/merge';
import { useMemo } from 'react';
import { API_BASE_URL } from './global-variables';

let apolloClient;

const httpLink = new HttpLink({
  uri: `${API_BASE_URL}/api/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const apolloCache = new InMemoryCache({
  // addTypename: false,
  typePolicies: merge(cellarApolloCache, checkoutApolloCache),
});

function createApolloClient() {
  return new ApolloClient({
    link: authLink.concat(httpLink),
    connectToDevTools: true,
    cache: apolloCache,
    typeDefs: [checkoutTypeDefs],
    defaultOptions: {
      mutate: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
