import mixpanel from 'mixpanel-browser';

export function initialize() {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
}

export const EVENTS = {
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  ADD_TO_CART: 'Add to Cart',
  REMOVE_TO_CART: 'Remove to Cart',
  SAVED_FOR_LATER: 'Saved for later',
  VIEW_CART: 'View Cart',
  CHECKOUT: 'Checkout',
  SELECT_FILTER: 'Select Filter',
  SELECT_SORT: 'Select Sort',
  SELECT_LIST_VIEW: 'Select List View',
  SELECT_GRID_VIEW: 'Select Grid View',
  SEARCH: 'Search',
  SELECT_BOTTLE: 'Select Bottle',
  HOVER_OVER_BOTTLE: 'Hover over Bottle',
  CLICK_BACK_OF_BOTTLE_PHOTO: 'Click back of bottle photo',
};

export default mixpanel;
