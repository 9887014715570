import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Label from './Label';
import { ReactComponent as CalendarIcon } from '@assets/svg/calendar.svg';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import tw, { styled } from 'twin.macro';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';

const IconWrapper = styled.div``;

const CustomInput = styled.input`
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  border-radius: 4px;
  ${tw`w-full mb-0 border border-gray-200 focus:border-gray-700 font-medium appearance-none py-3 px-3 text-gray-700 leading-tight focus:outline-none`}

  &:focus {
    + ${IconWrapper} {
      ${tw`border-gray-700`}
    }
  }
`;

const Wrapper = styled.div`
  .DayPicker-NavButton {
    ${tw`outline-none`}
  }

  .DayPickerInput-Overlay {
    ${tw`outline-none left-auto right-0`}
  }

  .DayPickerInput {
    display: block;
  }

  .DayPicker-Day {
    ${tw`rounded-none outline-none text-gray-700`}
  }

  .DayPicker-Day--today {
    ${tw`outline-none text-gray-900`}
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    ${tw`rounded-none outline-none bg-gray-900 text-white`}
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--selected
    ):not(.DayPicker-Day--outside):hover {
    ${tw`outline-none bg-gray-100`}
  }

  .DayPicker-Day--disabled {
    opacity: 0.3;
  }
`;

function DatePicker(props) {
  const inputRef = useRef(null);

  function toggleDatePicker() {
    inputRef.current && inputRef.current.focus && inputRef.current.focus();
  }

  return (
    <Wrapper className={`${props.className ? props.className : 'mb-4'}`}>
      {!!props.label && <Label htmlFor={props.name}>{props.label}</Label>}
      <DayPickerInput
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          fromMonth: new Date(),
          disabledDays: props.disabledDays,
        }}
        format="LL"
        placeholder="Select a date"
        component={(xProps) => (
          <div className="relative">
            <CustomInput
              ref={inputRef}
              classNames={{
                container: 'w-full',
              }}
              {...xProps}
              type={props.type || 'text'}
            />
            <IconWrapper
              onClick={toggleDatePicker}
              className="absolute cursor-pointer top-0 right-3 bottom-0 pl-3 flex flex-col justify-center border-l border-gray-200"
            >
              <CalendarIcon />
            </IconWrapper>
          </div>
        )}
        value={props.value}
        onDayChange={(day) => props.onChange && props.onChange(day)}
        inputProps={{ ref: null }}
      />
    </Wrapper>
  );
}

DatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  iconLeft: PropTypes.any,
  centered: PropTypes.bool,
  className: PropTypes.string,
  disabledDays: PropTypes.array,
};

export default DatePicker;
