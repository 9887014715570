import React from 'react';
import PropTypes from 'prop-types';

function InlineButton(props) {
  function getCssClasses() {
    let classes = [];

    if (props.className) {
      classes.push(props.className);
    }

    if (props.mode == 'semi-condensed') {
      classes.push(
        'font-barlow-semi-condensed text-gray-700 hover:text-gray-900'
      );
    } else {
      classes.push('font-barlow-condensed text-gray-400 hover:text-gray-500');
    }

    return classes.join(' ');
  }

  return (
    <button
      {...props}
      className={`select-none focus:outline-none border-none hover:underline cursor-pointer whitespace-nowrap ${getCssClasses()} font-light`}
      type={props.type}
    >
      <div className="flex flex-row items-center gap-2">
        {!!props.iconLeft && <div>{props.iconLeft}</div>}
        <div className="flex-grow">
          <span className="whitespace-nowrap">{props.label}</span>
        </div>
        {!!props.iconRight && <div>{props.iconRight}</div>}
      </div>
    </button>
  );
}

InlineButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  iconLeft: PropTypes.any,
  iconRight: PropTypes.any,
  mode: PropTypes.oneOf(['default', 'semi-condensed']),
};

export default InlineButton;
