import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Label from './Label';

const CustomTextArea = styled.textarea`
  border-radius: 4px;
`;

function TextArea(props) {
  return (
    <div className={`${props.className ? props.className : 'mb-4'}`}>
      {!!props.label && <Label htmlFor={props.name}>{props.label}</Label>}
      <div className="relative">
        <CustomTextArea
          {...props}
          className={`w-full border border-gray-200 focus:border-gray-700 font-medium appearance-none py-3 pl-3 pr-3 text-gray-700 leading-tight focus:outline-none`}
        />
      </div>
    </div>
  );
}

TextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  className: PropTypes.string,
};

export default TextArea;
