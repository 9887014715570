// import bottleplacementsWithWine from './bottle-placements-with-wine';
// import producerFilters from './producer-filters';
// import regionFilters from './region-filters';
// import typeFilters from './type-filters';
// import vintageFilters from './vintage-filters';

export const cellarApolloCache = {
  Wine: {
    keyFields: false,
  },
  Query: {
    fields: {
      // ...bottleplacementsWithWine,
      // ...producerFilters,
      // ...regionFilters,
      // ...typeFilters,
      // ...vintageFilters,
    },
  },
};
