import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../surfaces';
import { ReactComponent as XIcon } from '@assets/svg/x.svg';
import { ModalContext } from './Modal';

function BaseModal(props) {
  let { closeModal } = useContext(ModalContext);

  function getXIconCssClasses() {
    return props.xIconClass
      ? props.xIconClass
      : 'text-gray-600 group-hover:text-gray-900';
  }

  return (
    <Card
      className={`relative inline-block text-left align-bottom overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full`}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <span
        className="w-8 h-8 absolute right-5 top-4 block p-2 group cursor-pointer z-10"
        onClick={() => {
          props.onClose && props.onClose();
          closeModal();
        }}
      >
        <XIcon
          className={`stroke-current ${getXIconCssClasses()}`}
          height="14"
          width="14"
        />
      </span>
      {props.children}
    </Card>
  );
}

BaseModal.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  xIconClass: PropTypes.string,
};

export default BaseModal;
