import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PlusIcon } from '@assets/svg/plus.svg';
import { ReactComponent as MinusIcon } from '@assets/svg/minus.svg';
import styled from 'styled-components';

const CustomInput = styled.input`
  height: 38px;
  background: transparent;
`;

function InputCounter(props) {
  function getCssClasses() {
    let classes = [];

    if (props.disabled) classes.push('bg-gray-50 opacity-50');

    return classes.join(' ');
  }

  function getBtnCssClasses() {
    let classes = [];

    if (!props.disabled) classes.push('group-hover:text-black');

    return classes.join(' ');
  }

  function increase() {
    if (props.disabled) return;
    let newValue = (props.value || 0) + 1;
    if (!props.max || newValue <= props.max) {
      props.onChange && props.onChange(newValue);
    }
  }

  function decrease() {
    if (props.disabled) return;
    let newValue = (props.value || 0) - 1;

    if (!props.min || newValue >= props.min) {
      props.onChange && props.onChange(newValue);
    }
  }

  function handleChanges(value) {
    if (
      (!props.min || value >= props.min) &&
      (!props.max || value <= props.max)
    ) {
      props.onChange && props.onChange(parseInt(value));
    }
  }

  return (
    <div className="inline-block">
      <div
        className={`flex flex-row items-stretch rounded-sm border border-gray-200 select-none ${getCssClasses()}`}
      >
        <div
          className="cursor-pointer group pl-3 flex flex-row items-center"
          onClick={decrease}
        >
          <MinusIcon
            className={`stroke-current text-gray-400 ${getBtnCssClasses()}`}
          />
        </div>
        <div>
          <CustomInput
            value={props.value}
            defaultValue={props.defaultValue}
            disabled={props.disabled}
            type="text"
            className="w-10 border-none outline-none text-center"
            onChange={(ev) => handleChanges(ev.target.value)}
          />
        </div>
        <div
          className="cursor-pointer group pr-3 flex flex-row items-center"
          onClick={increase}
        >
          <PlusIcon
            className={`stroke-current text-gray-400 ${getBtnCssClasses()}`}
          />
        </div>
      </div>
    </div>
  );
}

InputCounter.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
};

export default InputCounter;
