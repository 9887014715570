import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { initialRoute, redirectIfLoggedIn } from '../../config/routes';

const Login = lazy(() => import('./pages/Login'));
const RecoverPassword = lazy(() => import('./pages/RecoverPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

export const accountRoutes = {
  login: '/login',
  recoverPassword: '/recover-password',
  resetPassword: '/reset-password',
};

function AccountRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={redirectIfLoggedIn(initialRoute, () => (
          <Login />
        ))}
        path={accountRoutes.login}
      />
      <Route
        exact
        component={() => <RecoverPassword />}
        path={accountRoutes.recoverPassword}
      />
      <Route
        exact
        component={() => <ResetPassword />}
        path={accountRoutes.resetPassword}
      />
    </Switch>
  );
}

export default AccountRoutes;
