import { gql } from '@apollo/client';
import checkoutField from './checkout-field';

export { checkoutVar } from './checkout-field';

export const checkoutTypeDefs = gql`
  extend type Query {
    checkout: Checkout
  }

  type Checkout {
    checkoutMethod: String!
    address: CheckoutAddress
    date: Date!
    time: String!
  }

  type CheckoutAddress {
    name: String!
    address: String!
    city: String!
    state: String!
    zip: String!
  }
`;

export const checkoutApolloCache = {
  Query: {
    fields: {
      ...checkoutField,
    },
  },
};
