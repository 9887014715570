import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  z-index: 9999;
`;

let ModalContext;
let { Provider } = (ModalContext = React.createContext());

function Modal(props) {
  let { options } = useContext(ModalContext);

  function getOpenStatusClass() {
    return !options.isOpen ? 'hidden' : 'visible';
  }

  return (
    <ModalWrapper
      className={`fixed inset-0 overflow-y-auto ${getOpenStatusClass()}`}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className={`fixed inset-0 transition-opacity`} aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        {/* This element is to trick the browser into centering the modal contents. */}
        {props.children}
      </div>
    </ModalWrapper>
  );
}

Modal.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

function useModal() {
  const [options, setOptions] = useState({
    isOpen: false,
    content: null,
    onClose: null,
  });

  let openModal = (content, options) => {
    setOptions({
      ...options,
      content,
      isOpen: true,
    });
  };

  let closeModal = () => {
    setOptions({
      isOpen: false,
      content: null,
      onClose: null,
    });
  };

  return { openModal, closeModal, options };
}

function ModalProvider({ children }) {
  let { openModal, closeModal, options } = useModal();
  return (
    <Provider value={{ openModal, closeModal, options }}>
      {children}
      <Modal isOpen={options.isOpen} onClose={options.onClose}>
        {options.content}
      </Modal>
    </Provider>
  );
}

ModalProvider.propTypes = { children: PropTypes.any };

export default Modal;
export { ModalProvider, ModalContext };
