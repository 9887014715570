import jwt_decode from 'jwt-decode';

const TOKEN_KEY = 'phenol.token';

function tokenIsExpired(token) {
  try {
    let decodedToken = jwt_decode(token);
    return decodedToken.exp * 1000 <= new Date().getTime();
  } catch (err) {
    return true;
  }
}

export function getAuthToken() {
  let token = localStorage?.getItem(TOKEN_KEY);
  return !tokenIsExpired(token) ? token : null;
}

export function isAuthenticated() {
  return !!getAuthToken();
}

export function isAnonymous() {
  return !isAuthenticated();
}

export function getCurrentUserProfile() {
  try {
    return jwt_decode(getAuthToken());
  } catch (err) {
    return null;
  }
}

export function signOut() {
  localStorage.removeItem(TOKEN_KEY);
}

export function setAuthToken(token) {
  localStorage?.setItem(TOKEN_KEY, token);
}
