import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as XIcon } from '@assets/svg/x.svg';

function Chip(props) {
  return (
    <div
      className={`bg-gray-100 flex flex-row items-center rounded-full mr-3 ${
        props.className ? props.className : ''
      }`}
    >
      <span className="text-sm font-barlow-semi-condensed leading-none pl-3 -mt-0.5 whitespace-nowrap">
        {props.label}
      </span>
      <span className="group p-3 cursor-pointer" onClick={props.onRemove}>
        <XIcon className="stroke-current text-sm text-gray-600 group-hover:text-gray-900" />
      </span>
    </div>
  );
}

Chip.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
};

export default Chip;
