import styled from 'styled-components';

function containMarginBottom(className) {
  return (
    className &&
    (className.indexOf('mb-') > -1 || className.indexOf('m-0') > -1)
  );
}

const Label = styled.label.attrs((props) => ({
  className: `block font-barlow-semi-condensed font-medium uppercase text-gray-700 ${
    containMarginBottom(props.className) ? '' : `mb-3`
  }`,
}))``;

export default Label;
