import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from 'tailwindcss/colors';

const Checkmark = styled.span`
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Label = styled.label`
  input:checked ~ ${Checkmark} {
    background-color: ${colors.black};

    &:after {
      display: block;
    }
  }
`;

function Checkbox(props) {
  return (
    <Label className="group block select-none relative pl-8 font-barlow-semi-condensed cursor-pointer">
      <span className="block text-gray-700 -mt-0.5">{props.label}</span>
      <input
        type="checkbox"
        checked={props.checked}
        onChange={props.onChange}
        className="absolute opacity-0 cursor-pointer h-0 w-0"
      />
      <Checkmark className="absolute w-5 h-5 left-0 top-0 bg-white border border-gray-600 group-hover:bg-gray-50" />
    </Label>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
