import React, { useContext, useEffect, useState } from 'react';
import { uniq } from 'lodash';
import PropTypes from 'prop-types';
import { SectionTitle } from '../typography';
import { Checkbox, Input } from '../forms';
import { ReactComponent as SearchIcon } from '@assets/svg/search.svg';
import { InlineButton, PrimaryButton } from '../buttons';
import styled from 'styled-components';
import Fuse from 'fuse.js';
import { ModalContext } from './Modal';
import BaseModal from './BaseModal';

const CheckboxContainers = styled.div`
  min-height: 50vh;
  max-height: 50vh;
  overflow: auto;
`;

function ModalCheckboxes(props) {
  const { closeModal } = useContext(ModalContext);
  const [selecteds, setSelecteds] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);

  function filterOptions() {
    if (!searchTerm) {
      return options;
    }
    const fuse = new Fuse(options, {
      threshold: 0.5,
      keys: ['label'],
    });
    return fuse.search(searchTerm).map((r) => ({ ...r.item, score: r.score }));
  }

  function deselectAll() {
    setSelecteds([]);
  }

  function apply() {
    props.onApply && props.onApply(selecteds);
    props.onClose && props.onClose();
    closeModal();
  }

  function onToggleCheckbox(value, checked) {
    if (checked) {
      selecteds.push(value);
    } else {
      selecteds.splice(selecteds.indexOf(value), 1);
    }
    setSelecteds(uniq(selecteds));
  }

  function isSelected(value) {
    return selecteds.indexOf(value) > -1;
  }

  useEffect(() => {
    setOptions(props.options);
    setSelecteds(
      props.options.filter((opt) => opt.checked).map((opt) => opt.value)
    );
  }, [props.options]);

  return (
    <BaseModal>
      <SectionTitle title={props.title} />
      <Input
        placeholder={`Search by ${props.title.toLowerCase()} name`}
        value={searchTerm}
        iconLeft={() => <SearchIcon />}
        onChange={(ev) => setSearchTerm(ev.target.value)}
        className="mb-6"
      />
      <CheckboxContainers>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {filterOptions().map((opt, index) => {
            return (
              <Checkbox
                key={`md-${index}`}
                label={opt.label}
                checked={isSelected(opt.value)}
                onChange={(ev) => {
                  onToggleCheckbox(opt.value, ev.target.checked);
                }}
              />
            );
          })}
        </div>
      </CheckboxContainers>
      <div className="border-t border-gray-200 pt-6 flex flex-row mt-6 items-center">
        <div className="flex-grow">
          <InlineButton label={'Deselect All'} onClick={deselectAll} />
        </div>
        <div>
          <PrimaryButton label={'Apply'} onClick={apply} />
        </div>
      </div>
    </BaseModal>
  );
}

ModalCheckboxes.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  onApply: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      checked: PropTypes.bool,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    })
  ),
};

export default ModalCheckboxes;
