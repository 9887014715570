import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Label from './Label';

const CustomInput = styled.input`
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  border-radius: 4px;
`;

function Input(props) {
  function getIconPadding() {
    return props.iconLeft ? 'pl-14' : 'pl-3';
  }

  return (
    <div className={`${props.className ? props.className : 'mb-4'}`}>
      {!!props.label && <Label htmlFor={props.name}>{props.label}</Label>}
      <div className="relative">
        {!!props.iconLeft && (
          <div className="absolute top-0 left-0 bottom-0 pl-4 flex flex-col justify-center">
            <props.iconLeft />
          </div>
        )}
        <CustomInput
          {...props}
          className={`w-full border border-gray-200 focus:border-gray-700 font-medium appearance-none py-3 pr-3 ${getIconPadding()} text-gray-700 leading-tight focus:outline-none`}
          type={props.type || 'text'}
        />
      </div>
    </div>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  iconLeft: PropTypes.any,
  centered: PropTypes.bool,
  className: PropTypes.string,
};

export default Input;
